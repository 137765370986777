<template>
  <div style="height: 600px">
    <codemirror
      v-model="flowJsonData"
      :options="options"
      class="code"
    ></codemirror>
  </div>
</template>

<script>
import "codemirror/lib/codemirror.css";
import { codemirror } from "vue-codemirror";

require("codemirror/mode/javascript/javascript.js");

export default {
  props: {
    data: Object,
  },
  data() {
    return {
      flowJsonData: {},
      options: {
        mode: { name: "javascript", json: true },
        readOnly: true,
        lineNumbers: true,
      },
    };
  },
  components: {
    codemirror,
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.flowJsonData = JSON.stringify(this.data, null, 4).toString();
    },
  },
};
</script>
<style lang="scss" scoped>
.code {
  height: 100%;
  &:deep() .CodeMirror {
    height: 100% !important;
  }
}
</style>
