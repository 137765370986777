<template>
  <div class="timedTask">
    <div class="searchCondition">
      <div class="searchConditionItem">
        <span>任务名称：</span>
        <a-input
          v-model.trim="name"
          placeholder="请输入任务名称"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">
          查询
        </a-button>
        <a-button type="primary" @click="newApiTask" icon="plus"
          >创建接口任务</a-button
        >
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableData"
      :pagination="tablePagination"
      size="small"
      :rowKey="(record) => record.id"
      :loading="loadingTable"
    >
      <span slot="action" slot-scope="text, record">
        <a-button
          type="link"
          @click="subtaskList(record)"
          :disabled="record.model == 'STREAM'"
          >子任务列表</a-button
        >
        <a-divider type="vertical" />
        <a-button type="link" @click="update_pipeline(record)">修改</a-button>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="left"
          okText="确认"
          cancelText="取消"
          @confirm="delete_pipeline(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>

    <a-modal
      :maskClosable="false"
      title="创建接口任务"
      v-model="createShow"
      width="420px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="名称">
          <a-input
            v-model="taskName"
            placeholder="请输入名称"
            style="width: 90%"
          />
        </a-form-item>
        <a-form-item>
          <span slot="label">
            模式
            <a-tooltip placement="left">
              <div slot="title">
                批处理会生成文件，产生子任务,
                流处理接收到数据后，会立即处理，不会产生子任务或文件，
                也没有后置任务
              </div>
              <a-icon
                type="question-circle"
                style="color: #1879ff; font-size: 14px"
              />
            </a-tooltip>
          </span>
          <a-radio-group v-model="model">
            <a-radio value="BATCH">批处理</a-radio>
            <a-radio value="STREAM">流处理</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="create_submit()">确 定</a-button>
          <a-button @click="createShow = false">取 消</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :maskClosable="false"
      title="子任务列表"
      v-if="subtaskListShow"
      v-model="subtaskListShow"
      width="1200px"
      :footer="null"
    >
      <subtask-list
        parentType="API_TASK_TEMPLATE"
        pipelineTaskType="API_TASK"
        :parentTaskId="parentTaskId"
      ></subtask-list>
    </a-modal>
  </div>
</template>
<script>
import subtaskList from "./subtaskList.vue";
export default {
  name: "apiTask",
  components: { subtaskList },
  data() {
    return {
      name: "",
      tableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
        },
        {
          title: "任务名称",
          ellipsis: true,
          dataIndex: "name",
          width: 200,
        },
        {
          title: "模式",
          ellipsis: true,
          dataIndex: "modelLabel",
          width: 100,
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
          width: 200,
        },
        {
          title: "创建人",
          ellipsis: true,
          dataIndex: "userName",
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 200,
        },
      ],
      tableData: [],
      loadingTable: false,
      tablePagination: {},
      currentPage: 1,
      createShow: false,
      taskName: "",
      model: "BATCH",
      subtaskListShow: false,
      parentTaskId: 0,
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    // 子任务列表
    subtaskList(val) {
      this.parentTaskId = val.id;
      this.subtaskListShow = true;
    },
    create_submit() {
      let data = {
        name: this.taskName,
        model: this.model,
        pipelineTaskType: "API_TASK_TEMPLATE",
      };
      this.$http.json_post("/pipelineTask/initBlankTask", data).then((res) => {
        this.$router.push({
          path: "/panel",
          query: { id: res.data.pipelineTask.id },
        });
      });
    },
    generateTaskName() {
      this.$http.get("/sysPipeline/generateName").then((res) => {
        if (res.result === 200) {
          this.taskName = res.data.name;
          this.createShow = true;
        }
      });
    },
    newApiTask() {
      this.generateTaskName();
    },
    // 点击重新创建
    recreateClick(val) {
      this.query(1);
    },
    update_pipeline(val) {
      this.$router.push({
        path: "/panel",
        query: { id: val.id },
      });
    },
    // 删除任务
    delete_pipeline(val) {
      let data = {
        id: val.id,
      };
      this.$http.json_post("/pipelineTask/delete", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 查询
    query(index) {
      this.loadingTable = true;
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
        name: this.name,
        parentTaskId: 0,
        pipelineTaskType: "API_TASK_TEMPLATE",
      };
      this.getPipelineLogList(data);
    },
    // 查询记录数据
    getPipelineLogList(data) {
      this.$http.get("/pipelineTask/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item) => {
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            item.modelLabel =
              item.model == "BATCH"
                ? "批处理"
                : item.model == "STREAM"
                ? "流处理"
                : "";
          });
          this.tableData = list;
          this.tablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.currentPage_data(current),
          };
          this.loadingTable = false;
        }
      });
    },
    // 翻页
    currentPage_data(index) {
      this.query(index);
    },
  },
};
</script>
