import Vue from "vue";
import Router from "vue-router";

import Menu from "@/components/Menu";
import credentialStuffingBatchList from "@/components/menu/credentialStuffingBatchList";
import MuchTask from "@/components/menu/MuchTask";
import taskList from "@/components/menu/taskList";
import etaApiTaskList from "@/components/menu/etaApiTaskList";
import etaTaskList from "@/components/menu/etaTaskList";
import tabTaskList from "@/components/menu/tabTaskList";
import apiTask from "@/components/menu/apiTask.vue";
import dataRecord from "@/components/menu/dataRecord.vue";
import timedTaskTab from "@/components/menu/timedTaskTab.vue";
import timedTask from "@/components/menu/timedTask.vue";
import timedTaskGroup from "@/components/menu/timedTaskGroup.vue";
import panel from "@/components/ef/panel";
import userList from "@/components/menu/userList";
import buriedLog from "@/components/menu/buriedLog";
import portTime from "@/components/menu/portTime";
import portDetail from "@/components/menu/portDetail.vue";
import statisticalData from "@/components/menu/statisticalData.vue";
import portCall from "@/components/menu/portCall";
import pipelineListOrigin from "@/components/menu/pipelineListOrigin";
import dataOrigin from "@/components/menu/dataOrigin.vue";
import fileList from "@/components/menu/fileList.vue";
import nodeListTab from "@/components/menu/nodeListTab.vue";
import nodeList from "@/components/menu/nodeList.vue";
import processNodeList from "@/components/menu/processNodeList.vue";
import pipelineList from "@/components/menu/pipelineList";
import formTaskTab from "@/components/menu/formTaskTab";
import formTask from "@/components/menu/formTask";
import apiList from "@/components/menu/apiList";
import apiInterface from "@/components/menu/apiInterface.vue";
import subtaskList from "@/components/menu/subtaskList.vue";
import ftpServer from "@/components/menu/ftpServer.vue";
import apiUserList from "@/components/menu/apiUserList.vue";
import umengFetchData from "@/components/menu/umengFetchData.vue";
import umengTaskDetail from "@/components/menu/umengTaskDetail.vue";
import umengDataSet from "@/components/menu/umengDataSet.vue";
import marketingLog from "@/components/menu/marketingLog.vue";
import fiveOneTalkFetchData from "@/components/menu/fiveOneTalkFetchData.vue";
import dzUpload from "@/components/menu/dzUpload.vue";
import tdTask from "@/components/menu/tdTask.vue";
import tdFetchData from "@/components/menu/tdFetchData.vue";
import tdPlaceConfig from "@/components/menu/tdPlaceConfig.vue";

Vue.use(Router);

export default new Router({
  mode: "history", //去掉#号
  routes: [
    {
      path: "/",
      name: "Menu",
      component: Menu,
      redirect: "/muchTask",
      children: [
        {
          path: "/umengFetchData",
          name: "umengFetchData",
          redirect: "/umengFetchData/umengTaskDetail",
          components: {
            first: umengFetchData,
          },
          children: [
            {
              path: "/umengFetchData/umengTaskDetail",
              name: "umengTaskDetail",
              components: {
                second: umengTaskDetail,
              },
            },
            {
              path: "/umengFetchData/umengDataSet",
              name: "umengDataSet",
              components: {
                second: umengDataSet,
              },
            },
          ],
        },
        {
          path: "/marketingLog",
          name: "marketingLog",
          components: {
            first: marketingLog,
          },
        },
        {
          path: "/dzUpload",
          name: "dzUpload",
          components: {
            first: dzUpload,
          },
        },
        {
          path: "/tdTask",
          redirect: "/tdTask/tdPlacePackageData",
          name: "tdTask",
          components: {
            first: tdTask,
          },
          children: [
            {
              path: "/tdTask/tdPlacePackageData",
              name: "tdPlacePackageData",
              components: {
                second: tdFetchData,
              },
            },
            {
              path: "/tdTask/tdPlaceFetchData",
              name: "tdPlaceFetchData",
              components: {
                second: tdFetchData,
              },
            },
            {
              path: "/tdTask/tdPlaceConfig/data",
              name: "tdPlaceConfigData",
              components: {
                second: tdPlaceConfig,
              },
            },
            {
              path: "/tdTask/tdPlaceConfig/black",
              name: "tdPlaceConfigBlack",
              components: {
                second: tdPlaceConfig,
              },
            },
          ],
        },
        {
          path: "/fiveOneTalkFetchData",
          name: "fiveOneTalkFetchData",
          components: {
            first: fiveOneTalkFetchData,
          },
        },
        {
          path: "/credentialStuffingBatchList",
          name: "credentialStuffingBatchList",
          components: {
            first: credentialStuffingBatchList,
          },
        },
        {
          path: "/panel",
          name: "panel",
          components: {
            first: panel,
          },
        },
        {
          path: "/muchTask",
          name: "MuchTask",
          components: {
            first: MuchTask,
          },
        },
        {
          path: "/taskList",
          redirect: "/taskList/etaTaskList",
          name: "taskList",
          components: {
            first: taskList,
          },
          children: [
            {
              path: "/taskList/etaTaskList",
              name: "etaTaskList",
              components: {
                second: etaTaskList,
              },
            },
            {
              path: "/taskList/etaApiTaskList",
              name: "etaApiTaskList",
              components: {
                second: etaApiTaskList,
              },
            },
          ],
        },
        {
          path: "/tabTaskList",
          name: "tabTaskList",
          redirect: "/tabTaskList/dataRecord",
          components: {
            first: tabTaskList,
          },
          children: [
            {
              path: "/tabTaskList/dataRecord",
              name: "dataRecord",
              components: {
                second: dataRecord,
              },
            },
            {
              path: "/tabTaskList/timedTaskTab",
              name: "timedTaskTab",
              redirect: "/tabTaskList/timedTaskTab/timedTask",
              components: {
                second: timedTaskTab,
              },
              children: [
                {
                  path: "/tabTaskList/timedTaskTab/timedTask",
                  name: "timedTask",
                  components: {
                    three: timedTask,
                  },
                },
                {
                  path: "/tabTaskList/timedTaskTab/timedTaskGroup",
                  name: "timedTaskGroup",
                  components: {
                    three: timedTaskGroup,
                  },
                },
                {
                  path: "/tabTaskList/timedTaskTab/subtaskList",
                  name: "subtaskList",
                  components: {
                    three: subtaskList,
                  },
                },
              ],
            },
            {
              path: "/tabTaskList/apiList",
              name: "apiList",
              redirect: "/tabTaskList/apiList/HTTPInterface",
              components: {
                second: apiList,
              },
              children: [
                {
                  path: "/tabTaskList/apiList/HTTPInterface",
                  name: "HTTPInterface",
                  components: {
                    three: apiInterface,
                  },
                },
                {
                  path: "/tabTaskList/apiList/KAFKAInterface",
                  name: "KAFKAInterface",
                  components: {
                    three: apiInterface,
                  },
                },
                {
                  path: "/tabTaskList/apiList/FTPInterface",
                  name: "FTPInterface",
                  components: {
                    three: apiInterface,
                  },
                },
                {
                  path: "/tabTaskList/apiList/apiUserList",
                  name: "apiUserList",
                  components: {
                    three: apiUserList,
                  },
                },
                {
                  path: "/tabTaskList/apiList/subtaskList",
                  name: "subtaskList",
                  components: {
                    three: subtaskList,
                  },
                },
              ],
            },
          ],
        },
        {
          path: "/formTask",
          name: "formTask",
          redirect: "/formTask/singleFormTaskTab",
          components: {
            first: formTaskTab,
          },
          children: [
            {
              path: "/formTask/singleFormTaskTab",
              name: "singleFormTaskTab",
              components: {
                second: formTask,
              },
            },
            {
              path: "/formTask/multipleFormTaskTab",
              name: "multipleFormTaskTab",
              components: {
                second: formTask,
              },
            },
          ],
        },
        {
          path: "/pipelineListOrigin",
          name: "pipelineListOrigin",
          redirect: "/pipelineListOrigin/pipelineList",
          components: {
            first: pipelineListOrigin,
          },
          children: [
            {
              path: "/pipelineListOrigin/pipelineList",
              name: "pipelineList",
              components: {
                second: pipelineList,
              },
            },
            {
              path: "/pipelineListOrigin/apiTask",
              name: "apiTask",
              components: {
                second: apiTask,
              },
            },
            {
              path: "/pipelineListOrigin/dataOrigin",
              name: "dataOrigin",
              components: {
                second: dataOrigin,
              },
            },
            {
              path: "/pipelineListOrigin/fileList",
              name: "fileList",
              components: {
                second: fileList,
              },
            },
            {
              path: "/pipelineListOrigin/nodeListTab",
              name: "nodeListTab",
              redirect: "/pipelineListOrigin/nodeListTab/nodeList",
              components: {
                second: nodeListTab,
              },
              children: [
                {
                  path: "/pipelineListOrigin/nodeListTab/nodeList",
                  name: "nodeList",
                  components: {
                    three: nodeList,
                  },
                },
                {
                  path: "/pipelineListOrigin/nodeListTab/processNodeList",
                  name: "processNodeList",
                  components: {
                    three: processNodeList,
                  },
                },
              ],
            },
            {
              path: "/pipelineListOrigin/ftpServer",
              name: "ftpServer",
              components: {
                second: ftpServer,
              },
            },
          ],
        },
        {
          path: "/userList",
          name: "userList",
          components: {
            first: userList,
          },
        },
        {
          path: "/buriedLog",
          name: "buriedLog",
          components: {
            first: buriedLog,
          },
        },
        {
          path: "/portTime",
          name: "portTime",
          redirect: "/portTime/portDetail",
          components: {
            first: portTime,
          },
          children: [
            {
              path: "/portTime/portDetail",
              name: "portDetail",
              components: {
                second: portDetail,
              },
            },
            {
              path: "/portTime/statisticalData",
              name: "statisticalData",
              components: {
                second: statisticalData,
              },
            },
          ],
        },
        {
          path: "/portCall",
          name: "portCall",
          components: {
            first: portCall,
          },
        },
      ],
    },
  ],
});
