<template>
  <div class="home">
    <a-layout>
      <a-layout-sider
        :trigger="null"
        collapsible
        v-model="collapsed"
        :style="{
          overflow: 'auto',
          height: '100%',
          position: 'fixed',
          left: 0,
          zIndex: '1000',
          width: '160px',
          minWidth: '160px',
        }"
      >
        <a-menu
          theme="dark"
          mode="inline"
          :selectedKeys="[selectedKeys]"
          :openKeys="openKeys"
          @click="selectClick"
          @openChange="onOpenChange"
        >
          <a-sub-menu v-for="item in list" :key="item.url">
            <span slot="title">
              <a-icon :type="item.iconType" />
              <span>{{ item.name }}</span>
            </span>
            <a-menu-item v-for="menu in item.children" :key="menu.url">
              {{ menu.name }}
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
        <div class="flex">
          <div class="showInfo" v-show="!collapsed">
            <a-dropdown class="dropdown">
              <span
                style="cursor: pointer; padding-left: 10px; font-size: 16px"
              >
                <a-avatar
                  class="avatar"
                  shape="circle"
                  :src="loginAvatarUrl"
                  v-if="loginAvatarUrl"
                />
                <span class="title">{{ userName }}</span>
              </span>
              <a-menu style="width: 150px" slot="overlay">
                <a-menu-item>
                  <a
                    href="javascript:void(0)"
                    @click="loginOut()"
                    style="
                      display: inline-block;
                      text-align: center;
                      width: 100%;
                      padding: 0;
                      margin: 0;
                    "
                  >
                    <a-icon type="poweroff" />
                    <span>退出登录</span>
                  </a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
          <a-icon
            class="trigger"
            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            @click="() => (collapsed = !collapsed)"
          />
        </div>
      </a-layout-sider>
      <a-layout
        :class="{
          isContent: true,
          onContent: collapsed,
        }"
      >
        <div
          :style="{
            background: 'rgb(255, 255, 255)',
            padding: $route.path == '/panel' ? '' : '0 20px',
          }"
        >
          <router-view
            v-if="isComponent"
            name="first"
            :collapsed="collapsed"
            :loginName="loginName"
            :userName="userName"
          />
        </div>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      collapsed: false,
      userName: "-",
      loginName: "",
      isComponent: false,
      loginAvatarUrl: "",
      list: [
        {
          name: "eta-api",
          url: "1",
          iconType: "menu",
          children: [
            {
              name: "批量任务",
              url: "/muchTask",
            },
            {
              name: "任务列表",
              url: "/taskList",
            },
          ],
        },
        {
          name: "pipeline流程",
          url: "2",
          iconType: "appstore",
          children: [
            {
              name: "任务列表",
              url: "/tabTaskList",
            },
            {
              name: "表单任务",
              url: "/formTask",
            },
            {
              name: "任务资源管理",
              url: "/pipelineListOrigin",
            },
          ],
        },
        {
          name: "管理后台",
          url: "3",
          iconType: "setting",
          children: [
            {
              name: "用户列表",
              url: "/userList",
            },
            {
              name: "撞库批次列表",
              url: "/credentialStuffingBatchList",
            },
            {
              name: "接口耗时统计",
              url: "/portTime",
            },
            {
              name: "接口调用记录",
              url: "/portCall",
            },
            {
              name: "埋点日志",
              url: "/buriedLog",
            },
          ],
        },
        {
          name: "其它",
          url: "4",
          iconType: "setting",
          children: [
            {
              name: "友盟取数",
              url: "/umengFetchData",
            },
            {
              name: "td撞库",
              url: "/tdTask",
            },
            {
              name: "营销记录",
              url: "/marketingLog",
            },
            {
              name: "51talk取数",
              url: "/fiveOneTalkFetchData",
            },
            {
              name: "大箴批次匹配",
              url: "/dzUpload",
            },
          ],
        },
      ],
      openKeys: [],
      rootSubmenuKeys: [],
    };
  },
  computed: {
    selectedKeys() {
      let path = "/" + this.$route.path.split("/")[1];
      return path;
    },
  },
  mounted() {
    this.login();
    let url = window.location.href;
    url = url.split("/");
    this.list.forEach((item) => {
      item.children.forEach((menu) => {
        if (menu.url === "/" + url[3]) {
          this.openKeys.push(item.url);
        }
      });
      this.rootSubmenuKeys.push(item.url);
    });
  },
  methods: {
    // 导航选择
    selectClick({ item, key, keyPath }) {
      let url = window.location.href;
      url = url.split("/");
      if (key === "/" + url[3]) {
        // 禁止重复跳转相同路由
        return;
      }
      this.$router.replace(key);
    },
    // 开启关闭导航栏
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    loginOut() {
      //退出登录
      this.$http.post("/alpha/user/logout").then((res) => {
        if (res.result == 200) {
          let url = window.location.href;
          url = url.split("/");
          url = url[0] + "//" + url[2];
          window.location.href = url;
        }
      });
    },
    login() {
      //用户登录
      this.$http.get("/alpha/user/info").then((res) => {
        if (res.result == 200) {
          this.userName = res.userName;
          this.loginName = res.loginName;
          this.loginAvatarUrl = res.avatar;
          this.isComponent = true;
        }
      });
    },
  },
};
</script>

<style scoped="scoped" lang="scss">
.home {
  width: 100%;
  height: 100%;

  .isContent {
    margin-left: 160px;
  }
  .onContent {
    margin-left: 50px;
  }
  .flex {
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
    left: 0;
    bottom: 0;
    width: calc(100% - 1px);
    padding-bottom: 10px;

    .showInfo {
      min-width: 100px;
      font-weight: 500;
      text-align: center;
      cursor: pointer;
      position: relative;

      .title {
        display: inline-block;
        margin-left: 10px;
        color: #409eff;
        font-size: 14px;
      }
      .dropdown {
        float: left;
      }
    }

    .trigger {
      font-size: 24px;
      padding: 0 12px;
      cursor: pointer;
      transition: all 0.3s;
      color: #1890ff;
      margin-top: 4px;
      margin-left: auto;
    }
  }
  &:deep(.ant-menu-submenu) {
    .ant-menu-submenu-title {
      padding: 0 16px !important;
    }
  }

  &:deep(.ant-menu-item) {
    padding-left: 32px !important;
  }

  &:deep(.ant-layout-sider-collapsed) {
    width: 50px !important;
    max-width: 50px !important;
    min-width: 50px !important;

    .ant-menu-inline-collapsed {
      width: 50px !important;

      .ant-menu-submenu-title {
        padding: 0 16px !important;
      }
    }
  }
}
</style>
