<template>
  <div class="commonOption">
    <div class="searchCondition">
      <div class="searchConditionItem">
        <span>名称：</span>
        <a-input
          placeholder="请输入名称"
          v-model.trim="searchCondition.name"
          @pressEnter="query(1)"
          allowClear
          @change="allowClearChange"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="enabled" slot-scope="text, record">
        <a-tag v-if="record.enabled" color="green"> 启用 </a-tag>
        <a-tag v-else color="red"> 禁用 </a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <a
          href="javascript:;"
          @click="getScheduleTaskGroupRelationList(1, record)"
          >关联任务</a
        >
        <a-divider type="vertical" />
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">是否确认删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      class="action-class"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="名称:">
          <a-input v-model="updateData.name" placeholder="请输入名称" />
        </a-form-item>
        <a-form-item label="启用状态:">
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="updateData.enabled"
          />
        </a-form-item>
        <a-form-item label="备注:">
          <a-input v-model="updateData.remark" placeholder="请输入备注" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="关联任务"
      v-model="scheduleTaskGroupRelationPageShow"
      :maskClosable="false"
      :footer="null"
      width="1000px"
    >
      <a-table
        bordered
        :rowClassName="$common.rowClassColor"
        :components="$common.getTitle(scheduleTaskGroupRelationTableColumns)"
        :columns="scheduleTaskGroupRelationTableColumns"
        :dataSource="scheduleTaskGroupRelationTableDataSource"
        :pagination="scheduleTaskGroupRelationTablePagination"
        :loading="scheduleTaskGroupRelationLoadingTable"
        size="small"
        :rowKey="(record) => record.id"
      >
      </a-table>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "commonOption",
  props: ["type"],
  data() {
    return {
      title: "",
      searchCondition: {
        name: "",
      },
      groupId: "",
      addKeyVisible: false,
      updateData: {},
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          ellipsis: true,
          width: 100,
        },
        {
          title: "名称",
          dataIndex: "name",
          ellipsis: true,
          width: 300,
        },
        {
          title: "启用状态",
          dataIndex: "enabled",
          ellipsis: true,
          width: 100,
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "备注",
          dataIndex: "remark",
          ellipsis: true,
          width: 150,
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          ellipsis: true,
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 160,
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      scheduleTaskGroupRelationPageNo: 1,
      scheduleTaskGroupRelationTableDataSource: [],
      scheduleTaskGroupRelationTableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          ellipsis: true,
          width: 100,
        },
        {
          title: "任务id",
          dataIndex: "taskId",
          ellipsis: true,
          width: 100,
        },
        {
          title: "任务名称",
          dataIndex: "taskName",
          ellipsis: true,
          width: 500,
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          ellipsis: true,
          width: 170,
        },
      ],
      scheduleTaskGroupRelationTablePagination: [],
      scheduleTaskGroupRelationLoadingTable: false,
      scheduleTaskGroupRelationPageShow: false,
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        type: this.type,
        ...this.searchCondition,
      };
      this.loadingTable = true;
      this.$http
        .get("/commonOptionInfo/commonOptionInfoPage", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    getScheduleTaskGroupRelationList(index, val) {
      if (index) {
        this.scheduleTaskGroupRelationPageNo = index;
      }
      if (val) {
        this.groupId = val.id;
      }
      let data = {
        pageNo: this.scheduleTaskGroupRelationPageNo,
        pageSize: 10,
        groupId: this.groupId,
      };
      this.scheduleTaskGroupRelationLoadingTable = true;
      this.$http
        .get("/scheduleTaskGroupRelation/scheduleTaskGroupRelationPage", data)
        .then((res) => {
          this.scheduleTaskGroupRelationLoadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.scheduleTaskGroupRelationTableDataSource = list;
            this.scheduleTaskGroupRelationTablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) =>
                this.getScheduleTaskGroupRelationList(current),
            };
            this.scheduleTaskGroupRelationPageShow = true;
          }
        })
        .catch((err) => {
          this.scheduleTaskGroupRelationLoadingTable = false;
        });
    },
    // 修改
    modifyClick(val) {
      this.title = "修改";
      this.addKeyVisible = true;
      this.groupId = val.id;
      this.updateData = {
        type: this.type,
        name: val.name,
        enabled: val.enabled,
        remark: val.remark,
      };
    },
    // 新增
    add() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.updateData = {
        type: this.type,
        name: "",
        enabled: true,
        remark: "",
      };
    },
    // 确定新增
    add_submit() {
      let data = { ...this.updateData };
      if (this.title === "新增") {
        this.$http
          .json_post("/commonOptionInfo/addCommonOptionInfo", data)
          .then((res) => {
            if (res.result === 200) {
              this.addKeyVisible = false;
              this.$message.success("添加成功");
              this.query();
            }
          });
      } else {
        data.id = this.groupId;
        this.$http
          .json_post("/commonOptionInfo/editCommonOptionInfo", data)
          .then((res) => {
            if (res.result === 200) {
              this.addKeyVisible = false;
              this.$message.success("修改成功");
              this.query();
            }
          });
      }
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      this.$http
        .json_post("/commonOptionInfo/deleteCommonOptionInfo", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("删除成功");
            this.query();
          }
        });
    },
  },
};
</script>
