<template>
  <div class="tabTaskList">
    <a-tabs v-model="activeName">
      <a-tab-pane tab="普通任务" key="1">
        <router-view
          name="second"
          v-if="activeName == '1'"
          :loginName="loginName"
          :userName="userName"
        ></router-view>
      </a-tab-pane>
      <a-tab-pane tab="定时任务" key="2">
        <router-view name="second" v-if="activeName == '2'"></router-view>
      </a-tab-pane>
      <a-tab-pane tab="接口任务" key="3">
        <router-view name="second" v-if="activeName == '3'"></router-view>
      </a-tab-pane>
    </a-tabs>
    <div class="lockStatus">
      <a-tooltip placement="leftBottom" overlayClassName="lockStatusTip">
        <template slot="title" v-if="lockStatus !== '已锁定'">
          撞库任务数量:
          <a-button type="link" @click="viewTaskList('1')">{{
            parseJobList.length
          }}</a-button
          ><br />
          pipeline任务数量:
          <a-button type="link" @click="viewTaskList('2')">{{
            pipelineTaskList.length
          }}</a-button>
        </template>
        <a-tag :color="statusColor">{{ lockStatus }}</a-tag>
      </a-tooltip>
      <a-button type="link" @click="modifyPipelineLockStatus">
        {{ lockStatus == "未锁定" ? "锁定" : "解除锁定" }}
      </a-button>
    </div>

    <a-modal
      v-if="viewTaskListShow"
      v-model="viewTaskListShow"
      width="1000px"
      :footer="null"
      title="任务列表"
      :maskClosable="false"
      :afterClose="viewTaskListClose"
    >
      <a-tabs v-model="taskActive">
        <a-tab-pane tab="撞库任务" key="1">
          <a-table
            :rowClassName="$common.rowClassColor"
            bordered
            :components="$common.getTitle(parseJobColumns)"
            :columns="parseJobColumns"
            :dataSource="parseJobList"
            size="small"
            :rowKey="(record) => record.id"
          >
          </a-table>
        </a-tab-pane>
        <a-tab-pane tab="pipeline任务" key="2">
          <a-table
            :rowClassName="$common.rowClassColor"
            bordered
            :components="$common.getTitle(pipelineTaskColumns)"
            :columns="pipelineTaskColumns"
            :dataSource="pipelineTaskList"
            size="small"
            :rowKey="(record) => record.id"
          >
            <span slot="executeSecond" slot-scope="text, record">
              <span v-if="record.status == 'SCHEDULED'">-</span>
              <span v-else>
                {{ $common.timeConsuming(record.executeSecond) }}
              </span>
            </span>
            <span slot="action" slot-scope="text, record">
              <a-button type="link" @click="jumpPipelineList(record)">
                跳转
              </a-button>
            </span>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "tabTaskList",
  props: ["loginName", "userName"],
  data() {
    return {
      activeName: "1",
      lockStatus: "未锁定",
      statusColor: "green",
      parseJobColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
          align: "center",
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
          width: 400,
        },
        {
          title: "类型",
          ellipsis: true,
          dataIndex: "typeName",
          width: 200,
        },
        {
          title: "创建人",
          ellipsis: true,
          dataIndex: "author",
          width: 200,
        },
      ],
      parseJobList: [],
      pipelineTaskColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
          align: "center",
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
          width: 300,
        },
        {
          title: "提交人",
          ellipsis: true,
          dataIndex: "userName",
          width: 100,
        },
        {
          title: "耗时",
          ellipsis: true,
          dataIndex: "executeSecond",
          scopedSlots: { customRender: "executeSecond" },
          align: "center",
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 150,
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 60,
        },
      ],
      pipelineTaskList: [],
      taskActive: "1",
      viewTaskListShow: false,
      refreshProgressTimer: null,
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/tabTaskList/dataRecord") {
          this.activeName = "1";
        } else if (newVal.indexOf("/tabTaskList/timedTaskTab") !== -1) {
          this.activeName = "2";
        } else if (newVal.indexOf("/tabTaskList/apiList") !== -1) {
          this.activeName = "3";
        }
      },
    },
    activeName: {
      handler(newVal) {
        if (newVal === "1") {
          this.$router.replace("/tabTaskList/dataRecord");
        } else if (newVal === "2") {
          this.$router.replace("/tabTaskList/timedTaskTab");
        } else {
          this.$router.replace("/tabTaskList/apiList");
        }
      },
    },
    viewTaskListShow: {
      handler(newVal) {
        if (!newVal) {
          this.viewTaskListClose();
        }
      },
    },
  },
  created() {
    this.getPipelineLockStatus();
  },
  methods: {
    getPipelineLockStatus() {
      this.$http.get("/pipelineTask/pipelineLockStatus").then((res) => {
        if (res.result == 200) {
          switch (res.data.status) {
            case "UNLOCK" || null:
              this.statusColor = "green";
              this.lockStatus = "未锁定";
              break;
            case "LOCKING":
              this.statusColor = "orange";
              this.lockStatus = "锁定中";
              break;
            case "LOCKED":
              this.statusColor = "red";
              this.lockStatus = "已锁定";
              break;
          }
          this.parseJobList = res.data.parseJobList;
          this.pipelineTaskList = res.data.pipelineTaskList;
        }
      });
    },
    viewTaskListClose() {
      clearInterval(this.refreshProgressTimer);
      this.refreshProgressTimer = null;
      console.log(this.refreshProgressTimer);
    },
    modifyPipelineLockStatus() {
      this.$http.post("/pipelineTask/modifyPipelineLockStatus").then((res) => {
        if (res.result == 200) {
          this.$message.success("修改成功");
          this.getPipelineLockStatus();
        }
      });
    },
    viewTaskList(taskActive) {
      this.taskActive = taskActive;
      this.viewTaskListShow = true;
      if (this.pipelineTaskList.length > 0) {
        this.refreshProgressTimer = setInterval(() => {
          let idList = this.pipelineTaskList.map((item) => {
            return item.id;
          });
          this.$http
            .get("/pipelineTask/queryTaskStateAndRunTime", {
              ids: idList.join(","),
            })
            .then((res) => {
              if (res.result === 200) {
                this.pipelineTaskList.forEach((item) => {
                  res.data.forEach((dataItem) => {
                    if (dataItem.id == item.id) {
                      this.$set(item, "executeSecond", dataItem.executeSecond);
                    }
                  });
                });
              }
            });
        }, 1000);
      }
    },
    jumpPipelineList(val) {
      this.$router.push({
        path: "/panel",
        query: { id: val.id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.tabTaskList {
  position: relative;

  .lockStatus {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
</style>
<style lang="scss">
.lockStatusTip {
  .ant-tooltip-inner {
    width: 160px !important;
  }
}
</style>
