<template>
  <div class="apiTaskList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        <span>名称：</span>
        <a-input
          v-model.trim="name"
          placeholder="请输入名称"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>{{ active == "2" ? "Topic" : "接口编码" }}：</span>
        <a-input
          v-model.trim="apiCode"
          :placeholder="'请输入' + (active == '2' ? 'Topic' : '接口编码')"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">
          查询
        </a-button>
        <a-button type="primary" @click="editFormShow = true" icon="plus"
          >新增{{
            active == "1" ? "HTTP" : active == "2" ? "KAFKA" : "FTP"
          }}接口</a-button
        >
      </div>
    </div>

    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableData"
      :pagination="tablePagination"
      size="small"
      :rowKey="(record) => record.id"
      :loading="loadingTable"
    >
      <span slot="pipelineTaskName" slot-scope="text, record">
        <a-tooltip placement="left">
          <span slot="title"> 跳转 </span>
          <a-button type="link" @click="jumpPipelineList(record)">
            {{ record.pipelineTaskName }}
          </a-button>
        </a-tooltip>
      </span>
      <span slot="enabled" slot-scope="text, record">
        <a-switch
          checked-children="已启用"
          un-checked-children="已禁用"
          v-model="text"
          @change="switchStatus(record)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a-button
          type="link"
          @click="subtaskList(record)"
          :disabled="type == 'KAFKA'"
          >子任务列表</a-button
        >
        <a-divider type="vertical" />
        <a-button type="link" @click="bind_user(record)" v-if="type == 'API'"
          >绑定用户</a-button
        >
        <a-divider type="vertical" v-if="type == 'API'" />
        <a-button type="link" @click="edit_api(record)">修改</a-button>
        <a-divider type="vertical" />
        <a-dropdown>
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            其它 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a-button
                type="link"
                size="small"
                @click="setPipelineTaskClean(record)"
                >设置清理周期</a-button
              >
            </a-menu-item>
            <a-menu-item v-if="type !== 'KAFKA'">
              <a-button type="link" size="small" @click="setConcurrent(record)"
                >设置并发数</a-button
              >
            </a-menu-item>
            <a-menu-item v-if="type == 'FTP'">
              <a-popconfirm
                placement="left"
                okText="确认"
                cancelText="取消"
                @confirm="ftpScanAtNow(record)"
              >
                <template slot="title"
                  >确认是否立即扫描{{ record.name }}</template
                >
                <a href="javascript:;" style="color: #1890ff">立即扫描</a>
              </a-popconfirm>
            </a-menu-item>
            <a-menu-item>
              <a-popconfirm
                placement="left"
                okText="确认"
                cancelText="取消"
                @confirm="delete_api(record)"
              >
                <template slot="title">确认是否删除{{ record.name }}</template>
                <a href="javascript:;" style="color: #ff4d4f">删除</a>
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>

    <a-modal
      :maskClosable="false"
      :title="title"
      v-model="editFormShow"
      width="500px"
      :afterClose="editFormClose"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="接口名称:">
          <a-input v-model="nameAdd" placeholder="请输入接口名称" allowClear />
        </a-form-item>
        <a-form-item :label="active == '2' ? 'Topic:' : '接口编码:'">
          <a-input
            v-model="apiCodeAdd"
            :placeholder="'请输入' + (active == '2' ? 'Topic' : '接口编码')"
            allowClear
            :disabled="title == '修改'"
          />
        </a-form-item>
        <a-form-item label="任务:">
          <a-select
            v-model="pipelineTaskId"
            placeholder="请选择任务"
            showSearch
            :filterOption="false"
            @search="getPipelineList"
          >
            <a-select-option
              v-for="item in pipelineList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="FTP账号:" v-if="active == '3'">
          <a-select
            v-model="ftpId"
            placeholder="请选择FTP账号"
            showSearch
            :filterOption="false"
            @search="searchFtpServerList"
            @change="ftpServerChange"
          >
            <a-select-option
              v-for="item in ftpServerList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="active == '3'">
          <span slot="label">
            文件名称
            <a-tooltip placement="left">
              <div slot="title">支持正则表达式,例如:[\w_@]+.csv</div>
              <a-icon
                type="question-circle"
                style="color: #1879ff; font-size: 14px"
              />
            </a-tooltip>
          </span>
          <a-input v-model="fileName" placeholder="请输入文件名称" />
        </a-form-item>
        <a-form-item v-if="active == '3'">
          <span slot="label">
            扫描路径
            <a-tooltip placement="left">
              <div slot="title">支持日期表达式,例如/${yyyyMMdd}</div>
              <a-icon
                type="question-circle"
                style="color: #1879ff; font-size: 14px"
              />
            </a-tooltip>
          </span>
          <a-input v-model="path" placeholder="请输入路径" />
        </a-form-item>
        <a-form-item label="启用状态:" v-if="title == '新增'">
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="enabled"
          />
        </a-form-item>
        <a-form-item label="被动模式:" v-if="active == '3' && ftpType == 'ftp'">
          <a-checkbox v-model="enterLocalPassiveMode" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="editForm_submit">确定</a-button>
          <a-button type="primary" @click="fileMatchTest" v-if="active == '3'"
            >测试</a-button
          >
          <a-button @click="editFormShow = false">取消</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      width="500px"
      title="测试ftp文件读取"
      v-model="fileMatchTestShow"
      :maskClosable="false"
    >
      <div v-for="item in fileMatchTestList">
        {{ item }}
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="fileMatchTestShow = false">关闭</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :maskClosable="false"
      title="绑定用户"
      v-model="editUserShow"
      width="59.5%"
      :afterClose="editUserClose"
      :footer="null"
    >
      <a-button type="primary" @click="addUserOption" icon="plus"
        >新增绑定用户</a-button
      >
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(formTableColumns)"
        :columns="formTableColumns"
        :dataSource="formTableData"
        :pagination="formTablePagination"
        size="small"
        style="margin-top: 10px"
        :rowKey="(record) => record.id"
        :loading="formLoadingTable"
      >
        <span slot="enabled" slot-scope="text, record">
          <a-switch
            checked-children="已启用"
            un-checked-children="已禁用"
            v-model="record.enabled"
            @change="edit_formItem(record, true)"
          />
        </span>
        <span slot="action" slot-scope="text, record">
          <a-button type="link" @click="edit_formItem(record)">修改</a-button>
          <a-divider type="vertical" />
          <a-popconfirm
            placement="left"
            okText="确认"
            cancelText="取消"
            @confirm="delete_formItem(record)"
          >
            <template slot="title">确认是否删除{{ record.apiCode }}</template>
            <a href="javascript:;" style="color: #ff4d4f">删除</a>
          </a-popconfirm>
        </span>
      </a-table>
    </a-modal>

    <a-modal
      :maskClosable="false"
      :title="titleUser"
      v-model="editUserFromShow"
      width="500px"
      :afterClose="editUserFromClose"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="接口编码:">
          <a-input
            v-model="apiCodeUserAdd"
            placeholder="请输入接口编码"
            allowClear
            disabled
          />
        </a-form-item>
        <a-form-item label="账号:">
          <a-select
            v-model="accessKey"
            placeholder="请选择账号"
            showSearch
            :filterOption="false"
            @search="getAccessKeyList"
          >
            <a-select-option
              v-for="item in accessKeyList"
              :key="item.accessKey"
              :value="item.accessKey"
            >
              {{ item.accessKey }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="启用状态:">
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="enabledUser"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="editUser_submit">确定</a-button>
          <a-button @click="editUserFromShow = false">取消</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :maskClosable="false"
      title="子任务列表"
      v-if="subtaskListShow"
      v-model="subtaskListShow"
      width="1200px"
      :footer="null"
    >
      <subtask-list
        parentType="API_INTERFACE"
        pipelineTaskType="API_TASK"
        :parentTaskId="parentTaskId"
      ></subtask-list>
    </a-modal>

    <a-modal
      width="500px"
      title="设置清理周期"
      v-model="setPipelineTaskCleanShow"
      :maskClosable="false"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="清理周期:">
          <a-select v-model="day" placeholder="请选择清理周期" allowClear>
            <a-select-option
              v-for="(item, index) in dayList"
              :key="index"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="setPipelineTaskCleanShow = false">取 消</a-button>
          <a-button type="primary" @click="setPipelineTaskCleanSubmit"
            >确 定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      width="500px"
      title="设置并发数"
      v-model="setConcurrentShow"
      :maskClosable="false"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="并发数:">
          <a-switch
            checked-children="限制"
            un-checked-children="不限制"
            v-model="maxConcurrentShow"
          />
          <a-input
            v-if="maxConcurrentShow"
            v-model.trim="maxConcurrent"
            placeholder="请输入并发数"
            allowClear
            style="width: 200px; margin-left: 10px"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="setConcurrentShow = false">取 消</a-button>
          <a-button type="primary" @click="setConcurrentSubmit">确 定</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import subtaskList from "./subtaskList.vue";
export default {
  props: ["active"],
  name: "apiTaskList",
  components: { subtaskList },
  data() {
    return {
      tableColumns: [
        {
          title: "序号",
          ellipsis: true,
          dataIndex: "id",
          align: "center",
          width: 100,
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
          width: 200,
        },
        {
          title: "接口编码",
          ellipsis: true,
          dataIndex: "apiCode",
          width: 200,
        },
        {
          title: "关联任务",
          ellipsis: true,
          dataIndex: "pipelineTaskName",
          scopedSlots: { customRender: "pipelineTaskName" },
          width: 200,
        },
        {
          title: "启用状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          align: "center",
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 250,
        },
      ],
      tableData: [],
      loadingTable: false,
      tablePagination: {},
      currentPage: 1,
      form_currentPage: 1,
      name: "",
      apiCode: "",
      editFormShow: false,
      nameAdd: "",
      apiCodeAdd: "",
      pipelineTaskId: undefined,
      enabled: true,
      title: "新增",
      id: "",
      pipelineList: [],
      editUserShow: false,
      formTableColumns: [
        {
          title: "序号",
          ellipsis: true,
          dataIndex: "id",
          align: "center",
          width: 100,
        },
        {
          title: "接口编码",
          ellipsis: true,
          dataIndex: "apiCode",
          width: 260,
        },
        {
          title: "账号",
          ellipsis: true,
          dataIndex: "accessKey",
          width: 260,
        },
        {
          title: "启用状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          align: "center",
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 150,
        },
      ],
      formTableData: [],
      formLoadingTable: false,
      formTablePagination: {},
      titleUser: "新增",
      editUserFromShow: false,
      apiCodeUserAdd: "",
      enabledUser: true,
      accessKeyList: [],
      accessKey: undefined,
      userId: "",
      apiCodeUser: "",
      type: "API",
      ftpTypeList: [
        {
          value: "ftp",
          label: "ftp",
        },
        {
          value: "sftp",
          label: "sftp",
        },
      ],
      ftpServerList: [],
      searchTimer: null,
      path: "/",
      ftpId: undefined,
      fileName: "",
      enterLocalPassiveMode: false,
      ftpType: "ftp",
      parentTaskId: 0,
      subtaskListShow: false,
      pipelineLogId: "",
      fileActive: "first",
      copyVisible: false,
      inviteCode: "",
      fileType: "txt",
      checkFileContentShow: false,
      fileContent: "",
      excelFileList: [],
      excelFileColumns: [],
      shareTimer: null,
      syncLoading: null,
      taskId: undefined,
      day: undefined,
      setPipelineTaskCleanShow: false,
      dayList: [
        { label: "一天", value: 1 },
        { label: "两天", value: 2 },
        { label: "三天", value: 3 },
        { label: "七天", value: 7 },
        { label: "十五天", value: 15 },
      ],
      fileMatchTestList: [],
      fileMatchTestShow: false,
      maxConcurrent: "",
      maxConcurrentShow: false,
      setConcurrentShow: false,
    };
  },
  watch: {
    fileActive: {
      handler(newVal, oldVal) {
        switch (newVal) {
          case "first":
            this.getFileListTableData(this.pipelineLogId, true);
            break;
          case "second":
            this.getFileListTableData(this.pipelineLogId, false);
            break;
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        this.tabClick(newVal);
      },
    },
  },
  created() {
    this.getPipelineList();
    this.getAccessKeyList();
  },
  methods: {
    tabClick(key) {
      switch (key) {
        case "1":
          this.type = "API";
          this.tableColumns[2].title = "接口编码";
          break;
        case "2":
          this.type = "KAFKA";
          this.tableColumns[2].title = "Topic";
          break;
        case "3":
          this.type = "FTP";
          this.tableColumns[2].title = "接口编码";
          this.getFtpServerList();
          break;
      }
      localStorage.setItem("apiTaskListActive", key);
      this.query(1);
    },
    searchFtpServerList(name) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
      this.searchTimer = setTimeout(() => {
        this.getFtpServerList(name);
      }, 300);
    },
    ftpServerChange() {
      let ftpServer = this.ftpServerList.filter((item) => {
        return item.id == this.ftpId;
      })[0];
      this.ftpType = ftpServer.type;
    },
    getFtpServerList(name) {
      let data = {
        name,
      };
      this.$http.get("/ftp/ftpServerList", data).then((res) => {
        if (res.result === 200) {
          this.ftpServerList.splice(0);
          this.ftpServerList.push(...res.data);
        }
      });
    },
    jumpPipelineList(val) {
      this.$router.push({
        path: "/panel",
        query: { id: val.pipelineTaskId },
      });
    },
    getAccessKeyList(val) {
      let data = {
        pageNo: 1,
        pageSize: 10,
        accessKey: val,
      };
      this.$http
        .get("/pipelineApiUser/pipelineApiUserPage", data)
        .then((res) => {
          if (res.result === 200) {
            this.accessKeyList.splice(0);
            this.accessKeyList.push(...res.data.records);
          }
        });
    },
    editUser_submit() {
      let data = {
        accessKey: this.accessKey,
        apiCode: this.apiCodeUserAdd,
        enabled: this.enabledUser,
      };
      if (this.titleUser == "新增") {
        this.$http
          .json_post(
            "/pipelineApiUserRelation/addPipelineApiUserRelation",
            data
          )
          .then((res) => {
            if (res.result == 200) {
              this.$message.success("新增成功");
              this.editUserFromShow = false;
              this.bind_user();
            }
          });
      } else {
        data.id = this.userId;
        this.$http
          .json_post(
            "/pipelineApiUserRelation/editPipelineApiUserRelation",
            data
          )
          .then((res) => {
            if (res.result == 200) {
              this.$message.success("修改成功");
              this.editUserFromShow = false;
              this.bind_user();
            }
          });
      }
    },
    editUserFromClose() {
      this.titleUser = "新增";
      this.apiCodeUserAdd = this.apiCodeAdd;
      this.accessKey = undefined;
      this.enabledUser = true;
      this.userId = "";
    },
    edit_formItem(val, enable) {
      this.titleUser = "修改";
      this.userId = val.id;
      this.apiCodeUserAdd = val.apiCode;
      this.accessKey = val.accessKey;
      this.enabledUser = val.enabled;
      if (enable) {
        this.editUser_submit();
      } else {
        this.editUserFromShow = true;
      }
    },
    delete_formItem(val) {
      let data = {
        id: val.id,
      };
      this.$http
        .json_post(
          "/pipelineApiUserRelation/deletePipelineApiUserRelation",
          data
        )
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("删除成功");
            this.bind_user();
          }
        });
    },
    editUserClose() {
      this.nameAdd = "";
      this.apiCodeAdd = "";
      this.enabled = true;
      this.id = "";
      this.form_currentPage = 1;
    },
    addUserOption() {
      this.titleUser = "新增";
      this.apiCodeUserAdd = this.apiCodeAdd;
      this.editUserFromShow = true;
    },
    // 子任务列表
    subtaskList(val) {
      this.parentTaskId = val.pipelineTaskId;
      this.subtaskListShow = true;
    },
    bind_user(val) {
      this.formLoadingTable = true;
      if (val) {
        this.apiCodeAdd = val.apiCode;
        this.enabled = val.enabled;
        this.id = val.id;
      }
      let data = {
        pageNo: this.form_currentPage,
        pageSize: 10,
        id: this.id,
        apiCode: this.apiCodeAdd,
      };
      this.$http
        .get("/pipelineApiUserRelation/pipelineApiUserRelationPage", data)
        .then((res) => {
          if (res.result === 200) {
            this.formTableData = res.data.records;
            this.formTablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.form_currentPage_data(current),
            };
            if (!this.editUserShow) {
              this.editUserShow = true;
            }
            this.formLoadingTable = false;
          }
        });
    },
    getPipelineList(val) {
      let data = {
        pageNo: 1,
        pageSize: 10,
        name: val,
        parentTaskId: 0,
        pipelineTaskType: "API_TASK_TEMPLATE",
      };
      this.$http.get("/pipelineTask/list", data).then((res) => {
        if (res.result === 200) {
          this.pipelineList.splice(0);
          this.pipelineList.push(...res.data.records);
        }
      });
    },
    editForm_submit() {
      let data = {
        pipelineTaskId: this.pipelineTaskId,
        name: this.nameAdd,
        apiCode: this.apiCodeAdd,
        enabled: this.enabled,
      };
      if (this.active == "3") {
        let extraInfo = {
          path: this.path,
          ftpId: this.ftpId,
          fileName: this.fileName,
          type: this.ftpType,
        };
        if (extraInfo.type == "ftp") {
          extraInfo.enterLocalPassiveMode = this.enterLocalPassiveMode;
        }
        data.extraInfo = JSON.stringify(extraInfo);
      }

      if (this.title == "新增") {
        data.type = this.type;
        this.$http
          .json_post("/pipelineApiInterface/addPipelineApiInterface", data)
          .then((res) => {
            if (res.result == 200) {
              this.$message.success("新增成功");
              this.editFormShow = false;
              this.query();
            }
          });
      } else {
        data.id = this.id;
        this.$http
          .json_post("/pipelineApiInterface/editPipelineApiInterface", data)
          .then((res) => {
            if (res.result == 200) {
              this.$message.success("修改成功");
              this.editFormShow = false;
              this.query();
            }
          });
      }
    },
    fileMatchTest() {
      let data = {
        ftpId: this.ftpId,
        path: this.path,
        fileName: this.fileName,
      };
      if (this.ftpType == "ftp") {
        data.enterLocalPassiveMode = this.enterLocalPassiveMode;
      }
      for (const key in data) {
        if (!data[key] && key !== "enterLocalPassiveMode") {
          this.$message.warning("请填写参数后测试");
          return;
        }
      }
      this.$http.json_post("/ftp/fileMatchTest", data).then((res) => {
        if (res.result == 200) {
          this.fileMatchTestShow = true;
          this.fileMatchTestList = res.data;
          if (res.data.length == 0) {
            this.fileMatchTestList.push("没有匹配到任何文件");
          }
        }
      });
    },
    editFormClose() {
      this.title = "新增";
      this.pipelineTaskId = undefined;
      this.nameAdd = "";
      this.apiCodeAdd = "";
      this.path = "/";
      this.ftpType = "ftp";
      this.ftpId = undefined;
      this.enterLocalPassiveMode = false;
      this.fileName = "";
      this.enabled = true;
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 查询
    query(index) {
      this.loadingTable = true;
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
        name: this.name,
        apiCode: this.apiCode,
        type: this.type,
      };
      this.getPipelineLogList(data);
    },
    // 查询记录数据
    getPipelineLogList(data) {
      this.$http
        .get("/pipelineApiInterface/pipelineApiInterfacePage", data)
        .then((res) => {
          if (res.result === 200) {
            this.tableData = res.data.records;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.currentPage_data(current),
            };
            this.loadingTable = false;
          }
        });
    },
    edit_api(val) {
      this.title = "修改";
      this.pipelineTaskId = val.pipelineTaskId;
      this.nameAdd = val.name;
      this.apiCodeAdd = val.apiCode;
      this.enabled = val.enabled;
      this.id = val.id;
      if (this.active == "3") {
        let extraInfo = val.extraInfo;
        if (extraInfo) {
          extraInfo = JSON.parse(extraInfo);
          this.path = extraInfo.path;
          this.ftpType = extraInfo.type;
          if (extraInfo.enterLocalPassiveMode) {
            this.enterLocalPassiveMode = extraInfo.enterLocalPassiveMode;
          } else {
            this.enterLocalPassiveMode = false;
          }
          this.ftpId = extraInfo.ftpId;
          this.fileName = extraInfo.fileName;
        }
      }
      this.editFormShow = true;
    },
    setPipelineTaskClean(val) {
      this.taskId = val.pipelineTaskId;
      let data = {
        taskId: this.taskId,
      };
      this.$http.get("/pipelineTask/pipelineTaskClean", data).then((res) => {
        if (res.result === 200) {
          if (res.data) {
            this.day = res.data.day;
            this.id = res.data.id;
          } else {
            this.day = undefined;
            this.id = undefined;
          }
          this.setPipelineTaskCleanShow = true;
        }
      });
    },
    setPipelineTaskCleanSubmit() {
      let data = {
        taskId: this.taskId,
        day: this.day,
        id: this.id,
      };
      this.$http
        .json_post("/pipelineTask/modifyPipelineTaskClean", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.setPipelineTaskCleanShow = false;
          }
        });
    },
    setConcurrent(val) {
      this.id = val.id;
      this.maxConcurrentShow = val.maxConcurrent !== -1;
      this.maxConcurrent = val.maxConcurrent;
      this.setConcurrentShow = true;
    },
    setConcurrentSubmit() {
      let data = {
        id: this.id,
        maxConcurrent: this.maxConcurrent,
      };
      if (!this.maxConcurrentShow) {
        data.maxConcurrent = -1;
      }
      this.$http
        .json_post("/pipelineApiInterface/updateConcurrent", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.setConcurrentShow = false;
            this.query();
          }
        });
    },
    ftpScanAtNow(val) {
      let data = {
        id: val.id,
      };
      this.$http
        .json_post("/pipelineApiInterface/ftpScanAtNow", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("扫描成功");
            this.query();
          }
        });
    },
    delete_api(val) {
      let data = {
        id: val.id,
      };
      this.$http
        .json_post("/pipelineApiInterface/deletePipelineApiInterface", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("删除成功");
            this.query();
          }
        });
    },
    switchStatus(val) {
      let data = {
        id: val.id,
        enabled: !val.enabled,
      };
      this.$http
        .json_post("/pipelineApiInterface/switchStatus", data)
        .then((res) => {
          if (res.result === 200) {
            if (val.enabled) {
              this.$message.success("禁用成功");
            } else {
              this.$message.success("启用成功");
            }
            this.query();
          }
        });
    },
    // 翻页
    currentPage_data(index) {
      this.query(index);
    },
    form_currentPage_data() {
      this.bind_user();
    },
  },
};
</script>
