<template>
  <div class="timedTaskTab">
    <a-tabs v-model="active">
      <a-tab-pane tab="定时任务" key="1">
        <router-view name="three" v-if="active == '1'"></router-view>
      </a-tab-pane>
      <a-tab-pane tab="子任务列表" key="2">
        <router-view
          name="three"
          pipelineTaskType="SCHEDULED_TASK"
          v-if="active == '2'"
        ></router-view>
      </a-tab-pane>
      <a-tab-pane tab="定时任务分组" key="3">
        <router-view
          name="three"
          type="SCHEDULED_TASK_GROUP"
          v-if="active == '3'"
        ></router-view>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  name: "timedTaskTab",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/tabTaskList/timedTaskTab/timedTask") {
          this.active = "1";
        } else if (newVal == "/tabTaskList/timedTaskTab/subtaskList") {
          this.active = "2";
        } else if (newVal == "/tabTaskList/timedTaskTab/timedTaskGroup") {
          this.active = "3";
        }
      },
    },
    active: {
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/tabTaskList/timedTaskTab/timedTask") return;
          this.$router.replace("/tabTaskList/timedTaskTab/timedTask");
        }
        if (newVal === "2") {
          if (this.$route.path == "/tabTaskList/timedTaskTab/subtaskList")
            return;
          this.$router.replace("/tabTaskList/timedTaskTab/subtaskList");
        }
        if (newVal === "3") {
          if (this.$route.path == "/tabTaskList/timedTaskTab/timedTaskGroup")
            return;
          this.$router.replace("/tabTaskList/timedTaskTab/timedTaskGroup");
        }
      },
    },
  },
};
</script>

<style></style>
